<template>
	<div id="orderList">
		<div class="el-content">
			<a-space>
				<a-input v-model:value="search.order_number" placeholde="订单编号" style="width: 400px;">
					<template #addonBefore>
						<a-select v-model:value="search.status" style="width: 90px">
							<a-select-option value="">全部</a-select-option>
							<a-select-option value="0">未支付</a-select-option>
							<a-select-option value="1">已支付</a-select-option>
						</a-select>
					</template>
				</a-input>
				<a-range-picker v-model:value="search.searchTime" />
				<a-button type="primary" @click="getOrderList(1,info.limit)">
					<i class="ri-search-2-line"></i>
					查询
				</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
				{title:'订单信息',dataIndex:'order_number'},
				{title:'应用信息',dataIndex:'apps',slots:{customRender:'apps'}},
				{title:'价格',dataIndex:'price',slots:{customRender:'price'}},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'下单时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #apps="{ record }">
					<div><span class="f12">版本：</span>{{ record.pk_json.server_name}}</div>
					<div><span class="f12">描述：</span>{{ record.pk_json.description}}</div>
				</template>
				<template #price="{ record }">
					<div><span class="f12">订单总价：</span><span style="color: red;">{{ record.total_price}}</span>元</div>
					<div v-if="record.pay_price"><span class="f12">实际支付：</span><span style="color: red;">{{ record.pay_price}}</span>元</div>
				</template>
				<template #status="{ record }">
					<a-tag :color="record.order_status == 1 ? '#00CC66':'#999'">{{ record.order_status == 1 ?'已支付':'未支付'}}</a-tag>
				</template>
				<template #action="{ record }">
					<a-button size="small" v-has="{ action: 'sass_order_del' }" type="link" @click="showRemark(record)">备注</a-button>
					<a-button size="small" v-has="{ action: 'sass_order_del' }" type="link" @click="deleteOrder(record.id)">删除</a-button>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination 
				show-size-changer 
				:default-current="info.page" 
				:total="info.count" 
				@showSizeChange="(page,e)=>{ getOrderList(info.page, e); }"
				@change="e=>{getOrderList(e, info.limit);}"/>
			</div>
		</div>
		<a-modal v-model:visible="remark.show" title="备注信息" @ok="saveOrderRemark" @cancel="remark.show = false" width="600px">
			<a-textarea v-model:value="remark.remark" placeholder="备注信息" :rows="6" />
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import programModel from '@/api/saas/app';
export default {
	setup(){
		const state = reactive({
			search: {
				order_number: "",
				searchTime: [],
				status: ''
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			remark:{
				show:false,
				order_id:0,
				remark:""
			}
		})
		getOrderList(1,state.info.limit)

		function getOrderList(page,limit){
			programModel.getProgramOrder(page,limit,state.search,res=>state.info = {limit,...res})
		}

		function showRemark(data){
			state.remark = {
				show:true,
				order_id:data.id,
				remark:data.remark
			}
		}

		const deleteOrder = id=>programModel.deleteProgramOrder(id,()=>getOrderList(state.info.page,state.info.limit))
		const saveOrderRemark = ()=>programModel.saveProgramOrderRemark(state.remark.order_id,state.remark.remark,()=>{
			state.remark.show = false
			getOrderList(state.info.page,state.info.limit)
		})
		return{
			...toRefs(state),
			getOrderList,
			deleteOrder,
			showRemark,
			saveOrderRemark
		}
	}
}
</script>

<style lang="scss">
.order-search-input {
	width: 350px !important;
}
.order-search-select {
	width: 200px !important;
}
.kd-admin-order {
	&-header {
		color: #000000;
		font-size: 14px;
		background: #f8f8f8;
		height: 40px;
		line-height: 40px;
		padding: 0 10px 0 10px;
	}
	&-li {
		width: 100%;
		border: 1px solid #f8f8f8;
		margin-top: 30px;
		&-row {
			width: 100%;
			padding: 0 10px;
			height: 40px;
			background: #f8f8f8;
			margin: auto;
			line-height: 40px;

			.th-create-time {
				color: #000000;
				font-size: 12px;
				font-weight: bold;
			}
			.th-order-number {
				color: #868686;
				font-size: 12px;
				margin-left: 20px;
			}
		}

		&-info {
			width: 100%;
			height: 100%;
			margin: auto;
			line-height: 60px;
			font-size: 14px;
		}
		.td-container {
			border-right: 1px solid #efefef;
		}
	}
	&-li:hover {
		border: 1px solid #409eff;
	}
}
</style>
